// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("/opt/build/repo/src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-api-list-clips-js": () => import("/opt/build/repo/src/pages/api/list-clips.js" /* webpackChunkName: "component---src-pages-api-list-clips-js" */),
  "component---src-pages-api-list-sounds-js": () => import("/opt/build/repo/src/pages/api/list-sounds.js" /* webpackChunkName: "component---src-pages-api-list-sounds-js" */),
  "component---src-pages-api-list-targets-js": () => import("/opt/build/repo/src/pages/api/list-targets.js" /* webpackChunkName: "component---src-pages-api-list-targets-js" */),
  "component---src-pages-api-list-voices-js": () => import("/opt/build/repo/src/pages/api/list-voices.js" /* webpackChunkName: "component---src-pages-api-list-voices-js" */),
  "component---src-pages-api-play-clip-js": () => import("/opt/build/repo/src/pages/api/play-clip.js" /* webpackChunkName: "component---src-pages-api-play-clip-js" */),
  "component---src-pages-api-play-file-js": () => import("/opt/build/repo/src/pages/api/play-file.js" /* webpackChunkName: "component---src-pages-api-play-file-js" */),
  "component---src-pages-api-play-sound-js": () => import("/opt/build/repo/src/pages/api/play-sound.js" /* webpackChunkName: "component---src-pages-api-play-sound-js" */),
  "component---src-pages-api-play-text-js": () => import("/opt/build/repo/src/pages/api/play-text.js" /* webpackChunkName: "component---src-pages-api-play-text-js" */),
  "component---src-pages-api-play-url-js": () => import("/opt/build/repo/src/pages/api/play-url.js" /* webpackChunkName: "component---src-pages-api-play-url-js" */),
  "component---src-pages-api-response-codes-js": () => import("/opt/build/repo/src/pages/api/response-codes.js" /* webpackChunkName: "component---src-pages-api-response-codes-js" */),
  "component---src-pages-clients-js": () => import("/opt/build/repo/src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/opt/build/repo/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sounds-js": () => import("/opt/build/repo/src/pages/sounds.js" /* webpackChunkName: "component---src-pages-sounds-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-voices-index-js": () => import("/opt/build/repo/src/pages/voices/index.js" /* webpackChunkName: "component---src-pages-voices-index-js" */),
  "component---src-pages-voices-languages-js": () => import("/opt/build/repo/src/pages/voices/languages.js" /* webpackChunkName: "component---src-pages-voices-languages-js" */),
  "component---src-pages-voices-locales-js": () => import("/opt/build/repo/src/pages/voices/locales.js" /* webpackChunkName: "component---src-pages-voices-locales-js" */)
}

